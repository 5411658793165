/* -----------------------------------------------------------------------------
 *
 * Components: Hero Content Only
 *
 * ----------------------------------------------------------------------------- */

@import '../../../../styles/utilities/common.css';

.component {
  padding: 0;

  @media (--medium) {
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
  }

  @media (--large) {
    background-position-x: calc( 50% + 350px );
  }
}

.component--holiday {
  background-size: contain, 242px;

  @media (--medium) {
    background-repeat: no-repeat, repeat;
    background-position: right, top left;
  }

  @media (--large) {
    background-position-x: right, 0;
  }

  @media (--xlarge) {
    background-size: contain, 600px;
  }
}

.component__hideMobileBackground {
  @media (--medium-max) {
    background-image: none !important;
  }
}

.component__hideMobileBackground.component--holiday {
  @media (--medium-max) {
    background-image: none, url('https://tundra.frontier.redventures.io/migration/sections/hero/holiday-background-2023.jpg') !important;
  }
}

.component__mobileBackground {
  width: 100vw;
  height: 320px;
  align-self: center;
  background-image: none;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: contain;
  box-shadow: inset 0 35px 15px -15px var( --color-midnight-express );

  @media (--medium) {
    display: none;
  }
}

.component__mobileBackground--white {
  box-shadow: inset 0 35px 15px -15px var( --color-white );
}

.component__mobileBackground--black {
  box-shadow: inset 0 35px 15px -15px #141415;
}

.component__mobileBackground--blue {
  box-shadow: inset 0 35px 15px -15px var( --color-electric-blue );
}

.component__mobileBackground--holiday {
  box-shadow: none;
  width: 111vw;
  position: relative;
  left: -5px;
  margin-top: 20px;
  background-position: center;
  height: 360px;
}

.component__mobileBackground--top {
  background-position-y: top;
}

.component__mobileBackground--center {
  background-position-y: center;
}

.component__mobileBackground--bottom {
  background-position-y: bottom;
}

/* For full-width background images */
.component__mobileBackgroundCover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 60%;
  background-image: inherit;

  @media (--medium) {
    padding-bottom: 0;
    background-position-x: 80%;
  }

  @media (--xlarge) {
    background-position-x: center;
  }
}

.component__wrapper {
  padding-top: 24px;
  display: flex;
  flex-flow: column nowrap;

  @media (--medium) {
    padding: 80px 10px;
  }

  @media (--large) {
    padding: 100px 0;
  }
}

/* Potentially needed for specific CSS override (not tested) */
.component__contentWrapper {

}

.component__content {
  margin: 16px 0;

  @media (--medium) {
    padding-right: 50px;
  }

  @media (--large) {
    margin: 24px 0;
    padding: 0;
  }
}

.component--holiday .component__heading {
  font-size: rem( 24px );

  @media (--medium) {
    font-size: rem( 32px );
  }

  @media (--large) {
    font-size: rem( 50px );
  }

  @media (--xlarge) {
    font-size: rem( 64px );
  }
}

.component--holiday .component__subheading {
  font-size: rem( 16px );

  @media (--large) {
    font-size: rem( 18px );
  }
}

.component--holiday .component__headingContainer,
.component--holiday .component__subheading {
  @media (--medium) {
    max-width: 75%;
  }

  @media (--large) {
    max-width: 85%;
  }
}

.component--holiday .component__contentWrapper {
  @media (--medium) {
    max-width: 75%;
  }

  @media (--large) {
    max-width: 80%;
  }

  @media (--xlarge) {
    max-width: 100%;
  }
}

.component--holiday input::placeholder{
  font-size: rem( 14px );
}

.component--holiday .component__wrapper {
  @media (--medium-max) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.component__selectAreasLegal {
  margin: 16px 0 0;
  font-size: rem(10px);

  @media(--large) {
    margin: 24px 0 0;
  }
}

.component__selectAreasLegal a {
  color: white; /* defaults links to white */
}

.component__pricingWrapper {
  margin: 20px 0 30px;

  @media (--large) {
    margin: 50px 0;
  }
}
