/* -----------------------------------------------------------------------------
 *
 * Components: Hero Address Check
 *
 * ----------------------------------------------------------------------------- */

@import '../HeroContentOnly/index.module.css';

.component {
  position: relative;

  @media (--large) {
    background-size: cover;
  }

  @media (--xlarge) {
    background-size: contain;
  }
}

.component__video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.component__videoToggle {
  position: absolute;
  bottom: 30px;
  right: 30px;
  height: 47px;
  width: 47px;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  font-size: 0;

  @media (--medium) {
    bottom: 40px;
    right: 40px;
  }
}

.component__mobileBackground {
  height: 270px;
  box-shadow: inset 0 0 15px 25px var( --color-midnight-express );

  @media (--medium-max) {
    display: flex;
    justify-content: center;
  }

  @media (--medium) {
    height: 320px;
    box-shadow: inset 0 0 15px 15px var( --color-midnight-express );
  }
}

.component__imageText {
  text-align: left;
  align-self: flex-end;
  font-size: rem( 10px );
  width: 230px;
  
  @media (--medium) {
    text-align: right;
    margin: 0;
    margin-left: auto;
    padding-bottom: 10px;
    padding-right: 17px;
    height: 0;
  }
}

.component__wrapper {
  @media (--medium) {
    padding: 80px 10px;
    background: none;
  }

  @media (--large) {
    padding: 100px 0;
  }
}

/* --- Horizon headline --- */
.component__headlineWrapper {
  margin-bottom: 16px;
  padding: 0;

  @media (--medium) {
    margin-bottom: 24px;
  }
}

.component__horizonHeadline {
  display: block;
  margin-bottom: 10px;
  padding-top: 0;
  line-height: 1;

  @media (--large) {
    font-size: rem( 56px );
  }

  @media (--xlarge) {
    font-size: unset;
  }
}

.component__horizonHeadline::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 27px;
  margin-right: 10px;
  background: var( --color-torch-red );

  @media (--medium) {
    width: 40px;
  }

  @media (--large) {
    height: 45px;
  }

  @media (--xlarge) {
    width: 145px;
    margin-right: 20px;
  }

  @media (min-width: 1600px) {
    width: 305px;
    margin-right: 40px;
  }
}

/* --- --- */

.component__headline > span {
  display: block;
}

.component__formLead {
  display: flex;
  align-items: center;
  margin: 12px 0 20px 0;
}

.component__icon {
  margin-right: 15px;
  color: var( --color-torch-red );
  font-size: rem( 34px );
}

/* tooltip overrides */
.component__subHeadline button {
  padding-right: 3px;
  padding-left: 3px;
  font-size: 60%;
  vertical-align: middle;
}

.component__contentWrapper {

}

.component__subheadlineTooltipWrapper {
  max-width: 650px;
  margin-bottom: 16px;
}

.component__tooltipInline {
  display: inline;
}
